import React from "react";
import styles from "./MenuOverlay.module.scss";
import cx from "classnames";
import { Link } from "gatsby";

import AFGCHeader from "../afgc-header";
import AFGCAnchor from "../../commons/afgc-anchor/AFGCAnchor";
import SVGContainer from "../../commons/svg-container/SVGContainer";
import Calendly from "../../commons/calendly/Calendly";
import CustomDialog from "../../commons/custom-dialog/CustomDialog";
import { MenuOverlayOptions } from "../../../enums/menu-overlay-options";
import { RouteLinks } from "../../../utils/route-links";
import { SocialLinks } from "../../../utils/social-links";
import { IconSizes } from "../../../enums/icon-sizes";
import { LOCATIONS } from "../../../utils/constants";
import { ContactLinks } from "../../../utils/contact-links";
import PinkAsterisk from "../../../images/icons/v1/asterisk-pink.svg";
import InstagramIcon from "../../../images/icons/instagram.inline.svg";
import PinterestIcon from "../../../images/icons/pinterest.inline.svg";
import FacebookIcon from "../../../images/icons/v1/facebook.inline.svg";
import LocationIcon from "../../../images/icons/location-icon.inline.svg";
import PhoneIcon from "../../../images/icons/v1/phone.inline.svg";
import MeetingIcon from "../../../images/icons/calendly.inline.svg";

interface Props {
  closePopUp: () => void;
  isOpen: boolean;
  selected?: MenuOverlayOptions;
}

const MenuOverlay: React.FC<Props> = (props: Props) => {
  const navLinks = [
    { title: "Home", value: MenuOverlayOptions.HOME, url: RouteLinks.index },
    {
      title: "Portfolio",
      value: MenuOverlayOptions.PORTFOLIO,
      url: RouteLinks.portfolio,
    },
    {
      title: "Testimonials",
      value: MenuOverlayOptions.TESTIMONIALS,
      url: RouteLinks.testimonial,
    },
    { title: "Blog", value: MenuOverlayOptions.BLOG, url: RouteLinks.blogsAll },
    {
      title: "Who am I",
      value: MenuOverlayOptions.WHO_AM_I,
      url: RouteLinks.whoAmI,
    },
  ];

  return (
    <CustomDialog closePopUp={props.closePopUp} isOpen={props.isOpen}>
      <div className={styles.container}>
        <div className={cx("column", styles.content)}>
          <AFGCHeader
            contentClassName={styles.content__heading}
            close
            iconClick={props.closePopUp}
          />
          <div className={cx("column", styles.content__main)}>
            <section className={styles.contentRow}>
              <section className={styles.left}>
                <img
                  src={PinkAsterisk}
                  alt="Asterisk"
                  className={styles.left__asterisk}
                />
                <div className={cx("column", styles.contentColumn)}>
                  <h2 className={cx("d2", styles.contentColumn__contact)}>
                    <Link
                      to={RouteLinks.contact}
                      className={cx(
                        props.selected === MenuOverlayOptions.CONTACT_ME &&
                          styles.contentColumn__selectedLink
                      )}
                    >
                      Contact Me
                    </Link>
                  </h2>
                  <section className={styles.left__content}>
                    <div className={cx("row", styles.iconRow)}>
                      <div className={styles.iconRow__column}>
                        <h4 className={cx("d4", styles.iconRow__heading)}>
                          Location
                        </h4>
                        {LOCATIONS.map((el, index) => (
                          <p
                            key={index}
                            className={cx("p3", styles.iconRow__subText)}
                          >
                            <SVGContainer
                              iconSize={IconSizes.IC__menuOverlayIcons}
                            >
                              <LocationIcon />
                            </SVGContainer>
                            {el}
                          </p>
                        ))}
                      </div>
                    </div>
                    <AFGCAnchor
                      ariaLabel="Phone Link"
                      url={`tel:${ContactLinks.phone}`}
                      className={styles.iconRow}
                    >
                      <div className={styles.iconRow__column}>
                        <h4 className={cx("d4", styles.iconRow__heading)}>
                          Phone
                        </h4>
                        <p className={cx("p3", styles.iconRow__subText)}>
                          <SVGContainer
                            iconSize={IconSizes.IC__menuOverlayIcons}
                          >
                            <PhoneIcon />
                          </SVGContainer>
                          {ContactLinks.phoneDisplay}
                        </p>
                      </div>
                    </AFGCAnchor>
                    <div className={cx("row", styles.iconRow)}>
                      <div className={styles.iconRow__column}>
                        <h4 className={cx("d4", styles.iconRow__heading)}>
                          Book a call or meeting
                        </h4>
                        <p
                          className={cx(
                            "p3",
                            styles.calendly,
                            styles.iconRow__subText,
                            styles.iconRow__calendly
                          )}
                        >
                          <SVGContainer
                            iconSize={IconSizes.IC__menuOverlayIcons}
                          >
                            <MeetingIcon />
                          </SVGContainer>
                          <Calendly text="Calendly" />
                        </p>
                        <p
                          className={cx(
                            "p3",
                            styles.calendly,
                            styles.iconRow__subText,
                            styles.iconRow__displaySmall
                          )}
                        >
                          <SVGContainer
                            iconSize={IconSizes.IC__menuOverlayIcons}
                          >
                            <MeetingIcon />
                          </SVGContainer>
                          <Calendly text="Book a call or meeting on Calendly" />
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className={styles.social}>
                    <AFGCAnchor
                      className={cx("p1", styles.social__data)}
                      ariaLabel="Share on Instagram"
                      url={SocialLinks.instagram}
                    >
                      <SVGContainer iconSize={IconSizes.IC__default}>
                        <InstagramIcon />
                      </SVGContainer>
                      <p>Instagram</p>
                    </AFGCAnchor>
                    <AFGCAnchor
                      className={cx("p1", styles.social__data)}
                      ariaLabel="Share on Pinterest"
                      url={SocialLinks.pinterest}
                    >
                      <SVGContainer iconSize={IconSizes.IC__default}>
                        <PinterestIcon />
                      </SVGContainer>
                      <p>Pinterest</p>
                    </AFGCAnchor>
                    <AFGCAnchor
                      className={cx("p1", styles.social__data)}
                      ariaLabel="Share on Facebook"
                      url={SocialLinks.facebook}
                    >
                      <SVGContainer iconSize={IconSizes.IC__default}>
                        <FacebookIcon />
                      </SVGContainer>
                      <p>Facebook</p>
                    </AFGCAnchor>
                  </section>
                </div>
              </section>
              <section className={styles.right}>
                <div className={cx("column", styles.contentColumn)}>
                  {navLinks.map((el, index) => {
                    let className;
                    if (props.selected === el.value) {
                      className = styles.contentColumn__selectedLink;
                    }
                    return (
                      <h2 className="d2" key={index}>
                        <Link to={el.url} className={cx(className)}>
                          {el.title}
                        </Link>
                      </h2>
                    );
                  })}
                </div>
              </section>
            </section>
            {/* <section className={cx("d8", styles.privacy)}>
            <Link to={RouteLinks.privacy} className="link">
              Privacy &amp; Policy
            </Link>
            <p>
              <Link to={RouteLinks.covid} className="link">
                Covid-19
              </Link>
            </p>
          </section> */}
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};

export default MenuOverlay;
