export enum MenuOverlayOptions {
  CONTACT_ME = "contact me",
  HOME = "home",
  PORTFOLIO = "portfolio",
  TESTIMONIALS = "testimonials",
  BLOG = "blog",
  WHO_AM_I = "who am i",
  PRIVACY = "privacy",
  COVID = "covid",
  WEDDING = "wedding",
  MATERNITY = "maternity",
  PORTRAIT = "portraits",
  FOOD = "food",
}
